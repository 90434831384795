import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/@ant-design+nextjs-registry@1.0.0_@ant-design+cssinjs@1.21.0_react-dom@18.3.1_react@18.3.1__r_xf52vn2dvmomeaawd6dhdee3oq/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@livekit+components-styles@1.0.12/node_modules/@livekit/components-styles/dist/general/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@livekit+components-styles@1.0.12/node_modules/@livekit/components-styles/dist/general/prefabs/index.css");
